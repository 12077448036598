import { PropertyDatabase } from './Propdb';

/**
 *
 * This wrapper takes the Property Database then extends it to have functionality that handles user-provided Custom Properties.
 * @class PropertyDatabaseCustomPropertyWrapper
 * @extends {PropertyDatabase} PropertyDatabase
 * @param dbjsons
 * @private
 */
export function PropertyDatabaseCustomPropertyWrapper(dbjsons) {
    'use strict';

    this._impl = new PropertyDatabase(dbjsons);

    /** @type CustomPropsValues */
    this.customAttrs = undefined;

    this.setCustomAttrs = (customAttrs) => this.customAttrs = customAttrs;

    this._attributeIsBlacklisted = (attrId) => this._impl._attributeIsBlacklisted(attrId);

    this._getAttributeAndValueIds = (dbId, attrId, valueId, integerHint) => this._impl._getAttributeAndValueIds(dbId, attrId, valueId, integerHint);

    this._ignoreAttribute = (attrId) => this._impl._ignoreAttribute(attrId);

    this.attributeHidden = (attrId) => this._impl.attributeHidden(attrId);

    this.setIdsBlob = (data) => this._impl.setIdsBlob(data);

    this.getObjectCount = () => this._impl.getObjectCount();

    this.getIdAt = (entId) => this._impl.getIdAt(entId);

    this.externalIdsLoaded = () => this._impl.externalIdsLoaded();

    this.getExternalIdMapping = (extIdFilter) => this._impl.getExternalIdMapping(extIdFilter);

    this.findRootNodes = () => this._impl.findRootNodes();

    this.nodeHasChild = (dbId) => this._impl.nodeHasChild(dbId);

    this.getNodeNameAndChildren = (node, skipChildren) => this._impl.getNodeNameAndChildren(node, skipChildren);

    this.buildDbIdToFragMap = (fragToDbId) => this._impl.buildDbIdToFragMap(fragToDbId);

    this.buildObjectTree = (rootId, fragToDbId, maxDepth, nodeStorage) =>
        this._impl.buildObjectTree(rootId, fragToDbId, maxDepth, nodeStorage);

    this.buildObjectTreeRec = (dbId, parent, dbToFrag, depth, maxDepth, nodeStorage) =>
        this._impl.buildObjectTreeRec(dbId, parent, dbToFrag, depth, maxDepth, nodeStorage);

    this.getSearchTerms = (searchText) => this._impl.getSearchTerms(searchText);

    this.bruteForceSearch = (searchText, attributeNames, searchOptions) =>
        this._impl.bruteForceSearch(searchText, attributeNames, searchOptions);

    this.bruteForceFind = (propertyName) => this._impl.bruteForceFind(propertyName);

    this.getLayerToNodeIdMapping = () => this._impl.getLayerToNodeIdMapping();

    this.findLayers = () => this._impl.findLayers();

    this.enumObjects = (cb, fromId, toId) => this._impl.enumObjects(cb, fromId, toId);

    this.getAttrChild = () => this._impl.getAttrChild();

    this.getAttrParent = () => this._impl.getAttrParent();

    this.getAttrName = () => this._impl.getAttrName();

    this.getAttrLayers = () => this._impl.getAttrLayers();

    this.getAttrInstanceOf = () => this._impl.getAttrInstanceOf();

    this.getAttrViewableIn = () => this._impl.getAttrViewableIn();

    this.getAttrXref = () => this._impl.getAttrXref();

    this.getAttrNodeFlags = () => this._impl.getAttrNodeFlags();

    this.findParent = (dbId) => this._impl.findParent(dbId);

    this.findDifferences = (dbToCompare, diffOptions, onProgress) =>
        this._impl.findDifferences(dbToCompare, diffOptions, onProgress);

    this.numberOfAttributes = () => this._impl.numberOfAttributes();

    this.numberOfValues = () => this._impl.numberOfValues();

    this.dtor = () => this._impl.dtor();

    this._customAttrIdOffset = this._impl.numberOfAttributes();
    this._customValueIdOffset = this._impl.numberOfValues();

    this.getObjectCustomProperties = (dbId, propsWanted) => this.customAttrs?.getObjectProperties(dbId, propsWanted, this._customAttrIdOffset, this._customValueIdOffset) ?? [];

    // ⬇ custom override functions ⬇

    this.getValueAt = (valueId) => {
        const customValueId = valueId - this._customValueIdOffset;
        if (customValueId >= 0)
            return this.customAttrs.getValueAt(customValueId);

        return this._impl.getValueAt(valueId);
    };

    this.getIntValueAt = (valueId) => {
        const customValueId = valueId - this._customValueIdOffset;
        if (customValueId >= 0)
            return this.customAttrs.getValueAt(customValueId);

        return this._impl.getIntValueAt(valueId);
    };

    this.getAttrValue = (attrId, valueId, integerHint) => {
        const customAttrId = attrId - this._customAttrIdOffset;
        if (customAttrId >= 0)
            return this.customAttrs.getValueAt(valueId - this._customValueIdOffset);

        return this._impl.getAttrValue(attrId, valueId, integerHint);
    };

    this._getObjectProperty = (attrId, valueId) => {
        const customAttrId = attrId - this._customAttrIdOffset;
        if (customAttrId >= 0) {
            return this.customAttrs._getObjectProperty(customAttrId, valueId - this._customValueIdOffset);
        }

        return this._impl._getObjectProperty(attrId, valueId);
    };

    this.getObjectProperties = (dbId, propFilter, ignoreHidden, propIgnored, categoryFilter) => {
        const result = this._impl.getObjectProperties(dbId, propFilter, ignoreHidden, propIgnored, categoryFilter) ?? {};
        const pushProperty = (attrId, valId) => {
            const prop = this.customAttrs.getAttributeAt(attrId);
            if (propFilter && propFilter.indexOf(prop.name) === -1 && propFilter.indexOf(prop.displayName) === -1 )
                return;

            if (categoryFilter && categoryFilter.indexOf(prop.category) === -1)
                return;

            if (propIgnored && (propIgnored.indexOf(prop.name) > -1 || propIgnored.indexOf(prop.displayName) > -1 ))
                return;

            result.properties.push(this.customAttrs._getObjectProperty(attrId, valId));
        };
        this.customAttrs?.enumObjectProperties(dbId, pushProperty, 0, 0);
        return result;
    };

    this.getAttributeDef = (attrId) => {
        const customAttrId = attrId - this._customAttrIdOffset;
        if (customAttrId >= 0)
            return this.customAttrs.getAttributeAt(customAttrId);

        return this._impl.getAttributeDef(attrId);
    };

    this.enumAttributes = (cb) => {
        this._impl.enumAttributes(cb);
        this.customAttrs?.enumAttributes(cb, this._customAttrIdOffset);
    };

    this.enumObjectProperties = (dbId, cb) => {
        this._impl.enumObjectProperties(dbId, cb);
        this.customAttrs?.enumObjectProperties(dbId, cb, this._customAttrIdOffset, this._customValueIdOffset);
    };

    this.getPropertiesSubsetWithInheritance = (dbId, desiredAttrIds, dstValueIds) => {
        const result = this._impl.getPropertiesSubsetWithInheritance(dbId, desiredAttrIds, dstValueIds);
        this.customAttrs?.enumObjectProperties(dbId, (attrId, valId) => {
            if (desiredAttrIds && !desiredAttrIds[attrId]) {
                return;
            }
            result.push(attrId, valId);
            if (dstValueIds) {
                dstValueIds[attrId] = valId;
            }
        }, this._customAttrIdOffset, this._customValueIdOffset);
        return result;
    };
}

const TypeMap = {
    "Boolean": 1,
    "Integer": 2,
    "Double": 3,
    "Float": 4,
    "Blob": 10,
    "DbKey": 11,
    "String": 20,
    "LocalizableString": 21,
    "DateTime": 22,
    "GeoLocation": 23,
    "Position": 24,
};

const EMPTY = "";
const EMPTY_ARRAY = [];

async function fetchWithPaging(url, init) {
    const { onPage } = init ?? {};
    const result = [];
    let isFirstPage = true;
    while(url) {
        const response = await fetch(url, init);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const payload = await response.json();
        url = payload.pagination?.nextUrl;
        if (onPage) {
            await onPage(payload, isFirstPage, !url);
        } else {
            result.push(payload);
        }
        isFirstPage = false;
    }
    return result;
}

class CustomPropsValues {

    constructor() {
        this.lastUpdated = new Date(0);  // min date (start of epoch)
        this.lastFetched = new Date(0);  // min date (start of epoch)
        this.customValueIds = new Map([[EMPTY, 1]]);
        this.customValues = [null, EMPTY];
        this.customAttributeValues = new Map();
    }

    hasObjectProperties(dbId) {
        return this.customAttributeValues.get(dbId) !== undefined;
    }

    enumObjectProperties(attributes, dbId, cb, attributesOffset, valuesOffset) {
        const props = this.customAttributeValues.get(dbId) ?? EMPTY_ARRAY;
        for (let i = 0; i < attributes.length; ++i) {
            let found = false;
            for(let j = 0; j < props.length;) {
                const attrId = props[j++];
                const valId = props[j++];
                if (i === attrId) {
                    cb(attrId + attributesOffset, valId + valuesOffset);
                    found = true;
                    break;
                }
            }
            if (!found) {
                cb(i + attributesOffset, 1 + valuesOffset);
            }
        }
    }

    internValue(value) {
        let valueId = this.customValueIds.get(value);
        if (valueId === undefined) {
            valueId = this.customValues.length;
            this.customValueIds.set(value, valueId);
            this.customValues.push(value);
        }
        return valueId;
    }

    // AECDS needs paging, the upper limit imposed by ACEDS is 5000 records. So we fetch all the records in a loop.
    async refreshCustomPropertiesValues(attributes, { baseUrl, headers, projectId, seedFileUrn, limit }) {

        const url = `${baseUrl}/v2/projects/${projectId}/versions/${encodeURIComponent(seedFileUrn)}/custom-properties?limit=${limit}`;
        try {
            const onPage = async (payload, isFirstPage, isLastPage) => {
                if (isFirstPage) {
                    this.customAttributeValues.clear();
                }
                for(const entry of payload.results) {
                    const attrId = attributes.customAttrIds.get(entry.propId);
                    if (attrId === undefined) {
                        continue;
                    }
                    const dbId = entry.svf2Id;
                    let avs = this.customAttributeValues.get(dbId);
                    if (avs === undefined) {
                        avs = [];
                        this.customAttributeValues.set(dbId, avs);
                    }
                    if (entry.value !== null) {
                        avs.push(attrId, this.internValue(entry.value));
                    }
                }
                if (isLastPage) {
                    this.lastUpdated = Date.parse(payload.lastModifiedAt);
                }
            };
            await fetchWithPaging(url,
            {
                headers: {
                    "Content-Type": "application/json",
                    ...headers
                },
                onPage
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
        this.lastFetched = Date.now();
        return this;
    }

    async setCustomPropertiesValues(attributes, { baseUrl, headers, projectId, seedFileUrn, selection, props }) {
        const url = `${baseUrl}/v2/projects/${projectId}/versions/${encodeURIComponent(seedFileUrn)}/custom-properties`;
        const body = {};
        const assigned = {};
        const removed = [];
        for(const [propId, propValue] of Object.entries(props)) {
            if (propValue === undefined || propValue === '') {
                removed.push(propId);
            } else {
                assigned[propId] = propValue;
            }
        }
        if (Object.entries(assigned).length > 0) {
            body.assignments = [{ svf2Ids: selection, props: assigned }];
        }
        if (removed.length > 0) {
            body.removals = [{ svf2Ids: selection, propIds: removed }];
        }
        var response = await fetch(url,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            ...headers
          }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const results = await response.json();
        if (results.status === 'FAILED') {
            return results;
        }
        // optimistic update custom property values
        const propsEntries = Object.entries(props);
        for(const dbId of results.successfulSvf2Ids) {
            for(const [propId, propValue] of propsEntries) {
                const attrId = attributes.customAttrIds.get(propId);
                if (attrId === undefined) {
                    continue;
                }
                let avs = this.customAttributeValues.get(dbId);
                if (avs === undefined) {
                    avs = [];
                    this.customAttributeValues.set(dbId, avs);
                }
                let index = -1;
                for(let j = 0; j < avs.length; j += 2) {
                    if (avs[j] === attrId) {
                        index = j;
                    }
                }
                if (propValue === undefined || propValue === '') {
                    if (index >= 0) {
                        avs.splice(index, 2);
                    }
                } else {
                    const valueId = this.internValue(propValue);
                    if (index < 0) {
                        avs.push(attrId, valueId);
                    } else {
                        avs[index+1] = valueId;
                    }
                }
                if (avs.length === 0) {
                    this.customAttributeValues.delete(dbId);
                }
            }
        }
        return results;
    }
}

class CustomPropsCacheEntry {

    constructor() {
        const epoch = new Date(0); // min date (start of epoch)
        this.attributes = {
            lastFetched: epoch,
            lastUpdated: epoch,
            customAttrIds: new Map(),
            customAttrs: []
        };
        /** @type {CustomPropsValues | Promise<CustomPropsValues | null>} */
        this.values = null;
    }

    getValueAt(valueId) {
        return this.values.customValues[valueId];
    }

    getAttributeAt(attrId) {
        return this.attributes.customAttrs[attrId];
    }

    _getObjectProperty(attrId, valueId) {
        const customAttr = this.getAttributeAt(attrId);
        // map value to expected shape
        return {
            displayName: customAttr.displayName ?? customAttr.name,
            displayValue: this.getValueAt(valueId),
            displayCategory: customAttr.category,
            attributeName: customAttr.name,
            type: customAttr.dataType,
            units: customAttr.dataTypeContext,
            hidden: false,
            precision: customAttr.precision,
            propType: customAttr.propType,
            propertyHash: customAttr.propertyHash
        };
    }

    hasObjectProperties(dbId) {
        return this.values?.hasObjectProperties(dbId);
    }

    enumAttributes(cb, offset) {
        this.attributes.customAttrs.forEach((attr, index) => {
            cb(index + offset, attr);
        });
    };

    enumObjectProperties(dbId, cb, attributesOffset, valuesOffset) {
        return this.values?.enumObjectProperties(this.attributes.customAttrs, dbId, cb, attributesOffset, valuesOffset);
    }

    async parseResponse(response, processLine) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let { value: chunk, done: readerDone } = await reader.read();
        let readString = chunk ? decoder.decode(chunk) : '';

        const re = /\r\n|\n|\r/g;
        let startIndex = 0;
        let line = null;

        for (;;) {
            line = re.exec(readString);
            if (!line) {
                if (readerDone) {
                    break;
                }
                const remainder = readString.substring(startIndex);
                ({ value: chunk, done: readerDone } = await reader.read());
                readString = remainder + (chunk ? decoder.decode(chunk) : '');
                startIndex = re.lastIndex = 0;
                continue;
            }
            processLine(readString.substring(startIndex, line.index));
            startIndex = re.lastIndex;
        }
        if (startIndex < readString.length) {
            // last line didn't end in a newline char
            processLine(readString.substring(startIndex));
        }
    }

    async refreshCustomProperties({ baseUrl, headers, projectId, seedFileUrn, propertyFilter, limit }) {

        const url = `${baseUrl}/v2/projects/${projectId}/lineages/${encodeURIComponent(seedFileUrn)}/custom-properties/fields`;
        var response = await fetch(url,
        {
            headers: {
                "Content-Type": "application/json",
                ...headers
            }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const internedValues = new Map([[EMPTY, EMPTY]]);
        const customAttrIds = new Map();
        const customAttrs = [];
        const intern = (v) => {
            if (v === undefined || v === null) {
                return null;
            }
            let interned = internedValues.get(v);
            if (interned === undefined) {
                interned = v;
                internedValues.set(v, v);
            }
            return interned;
        };
        const processLine = (line) => {
            try {
                const field = JSON.parse(line);
                if (propertyFilter && !propertyFilter.has(field.key)) {
                    return;
                }
                const customPropertyDef = {
                    propertyHash: field.key,
                    category: intern(field.category),
                    name: field.name,
                    displayName: field.displayName,
                    dataType: TypeMap[field.type] ?? 0,
                    dataTypeContext: intern(field.uom) ?? EMPTY,
                    flags: 0,
                    precision: intern(field.precision) ?? 0,
                    propType: intern(field.propType),
                    parameterId: field.parameterId,
                    parameterTypeId: intern(field.parameterTypeId),
                    defaultValue: intern(field.defaultValue),
                    applicableTo: field.applicableTo
                };
                var attrId = customAttrIds.get(customPropertyDef.propertyHash);
                if (!attrId) {
                    attrId = customAttrs.length;
                    customAttrs.push(customPropertyDef);
                }
                customAttrIds.set(customPropertyDef.propertyHash, attrId);
            } catch (e) {
                console.error(e);
                console.log(line);
                throw e;
            }
        };

        await this.parseResponse(response, processLine);
        const attributes = this.attributes;
        attributes.customAttrIds = customAttrIds;
        attributes.customAttrs = customAttrs;
        attributes.lastFetched = Date.now();
        return this;
    }

    async acquireValues({ baseUrl, headers, projectId, seedFileUrn, worker, dbPath, limit }, maxAge = 1000) {
        let values = this.values;
        try {
            if (values instanceof Promise) {
                // fetching is already in progress
                values = await values;
            } else if (values === null || (maxAge && (Date.now() - values.lastFetched) > maxAge)) {
                values ??= new CustomPropsValues();
                const promise = values.refreshCustomPropertiesValues(this.attributes, { baseUrl, headers, projectId, seedFileUrn, limit });
                this.values = promise;
                await promise;
                this.values = values;
            }
            var pdb = worker.pdbCache?.get(dbPath)?.pdb;
            if (pdb && 'setCustomAttrs' in pdb) {
                pdb.setCustomAttrs(this);
            }

        } catch (err) {
            this.values = null;
            throw err;
        }
        return values;
    }

    async setCustomPropertiesValues({ baseUrl, headers, projectId, seedFileUrn, selection, props, worker, dbPath, limit }) {
        const values = await this.acquireValues({baseUrl, headers, projectId, seedFileUrn, worker, dbPath, limit}, 0);
        return await values.setCustomPropertiesValues(this.attributes, { baseUrl, headers, projectId, seedFileUrn, selection, props });
    }

    async applyDefinitions({ baseUrl, headers, projectId, seedFileUrn, bindings, limit }) {
        // write bindings
        const url = `${baseUrl}/v2/projects/${projectId}/lineages/${encodeURIComponent(seedFileUrn)}/custom-properties/bindings`;
        const init = {
            method: 'POST',
            body: JSON.stringify(bindings),
                  headers: {
                "Content-Type": "application/json",
                ...headers
            }
        };
        // TODO: remove after contingency is no longer required
        delete init.headers['x-use-contingency'];
        var response = await fetch(url, init);
        if (response.ok) {
            const propertyFilter = new Set(this.attributes.customAttrIds.keys());
            bindings.forEach(({ propId, isDeleted }) => isDeleted? propertyFilter.delete(propId): propertyFilter.add(propId));
            await this.refreshCustomProperties({ baseUrl, headers, projectId, seedFileUrn, propertyFilter, limit });
            await this.values.refreshCustomPropertiesValues(this.attributes, { baseUrl, headers, projectId, seedFileUrn, limit });
            return this;
        }
        throw new Error(response.statusText);
    }
}

export class CustomPropsCache {

    constructor() {
        /** @type {Record<string, CustomPropsCacheEntry|Promise<CustomPropsCacheEntry>>} */
        this._cache = {};
    }

    delete(dbPath) {
        delete this._cache[dbPath];
    };

    async acquireDefinitions({ baseUrl, headers, projectId, seedFileUrn, dbPath }, maxAge = 30000) {
        let entry = this._cache[dbPath];
        try {
            if (entry instanceof Promise) {
                // fetching is already in progress
                entry = await entry;
            } else if (entry === undefined || (maxAge > 0 && (Date.now() - entry.attributes.lastFetched) > maxAge)) {
                entry ??= new CustomPropsCacheEntry();
                const promise = entry.refreshCustomProperties({ baseUrl, headers, projectId, seedFileUrn });
                this._cache[dbPath] = promise;
                await promise;
                this._cache[dbPath] = entry;
            }
        } catch (err) {
            delete this._cache[dbPath];
            throw err;
        }
        return entry;
    }

    async applyDefinitions({ baseUrl, headers, projectId, seedFileUrn, dbPath, bindings, limit }){
        let entry = (this._cache[dbPath] ??= new CustomPropsCacheEntry());
        if (entry instanceof Promise) {
            // fetching is already in progress
            entry = await entry;
        }
        await entry.applyDefinitions({ baseUrl, headers, projectId, seedFileUrn, dbPath, bindings, limit });
        return entry;
    }
}

export function acquireCustomPropsCache(host) {
    let customPropsCache = host.customPropsCache;
    if (!customPropsCache) {
        host.customPropsCache = customPropsCache = new CustomPropsCache();
    }
    return customPropsCache;
}
